import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const LinerStyles = ({ header = "Choose Your Scene" }) => {
  return (
    <div className="pt-8 pb-12 px-4 text-center">
      <h2 className="text-center py-4">{header}</h2>
      <div className="grid cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-3xl mx-auto">
        <Link
          className="grid gap-2"
          to="/window-well-liners/?style=liner-brown-stone"
        >
          <StaticImage
            alt="Brown stone window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/decorative-stone.jpg"}
          />
          <p>Brown Stone</p>
        </Link>
        <Link
          className="grid gap-2"
          to="/window-well-liners/?style=liner-gray-stone"
        >
          <StaticImage
            alt="Gray stone window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/grey-stone.jpg"}
          />
          <p>Gray Stone</p>
        </Link>
        <Link
          className="grid gap-2"
          to="/window-well-liners/?style=liner-gray-brick"
        >
          <StaticImage
            alt="Gray brick window well liner"
            className="rounded-lg"
            src={"../../../static/images/liners/grey-brick.jpg"}
          />
          <p>Gray Brick</p>
        </Link>
      </div>
    </div>
  );
};
